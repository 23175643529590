import * as styles from "./CookieBar.module.css";

import CookieConsent, { Cookies } from "react-cookie-consent";
import React, { useRef } from "react";

import ButtonElement from "../../../UI/Button/ButtonElement/ButtonElement";
import { ButtonStyle } from "../../../../models/ComponentProps/ButtonProps.model";
import { Link } from "gatsby";

/**
 * Component for Cookie Bar where user can accept / decline cookies
 * @returns Bar at bottom of the screen where the user can accept / decline cookies
 */
const CookieBar = () => {
  const cookieConsentRef = useRef<CookieConsent>(null);

  /**
   * Adds years to a given date
   * @param date Date to add years to
   * @param years Number of years to add
   * @returns Date with years added
   */
  const addYears = (date: Date, years: number) => {
    date.setFullYear(date.getFullYear() + years);
    return date;
  };

  /**
   * When the user presses the accept button
   */
  const onAcceptAllHandler = () => {
    // Sets end date for the cookies. Currently set to 1 year.
    const curDate = new Date();
    const curDatePlus1Year = addYears(curDate, 1);

    // Google analytics is the default cookie for the cookie bar.
    // The below sets the additional cookies needed. Eventually it
    // will be worth setting up a cookie window where the user can
    // accept / decline each party's cookies individually.
    Cookies.set("gatsby-gdpr-facebook-pixel", "true", {
      expires: curDatePlus1Year,
    });
    Cookies.set("gatsby-gdpr-hotjar", "true", { expires: curDatePlus1Year });

    // Google and Facebook scripts will already have been set up
    // with the consent set to false. This sets the consent to true
    // and initiallises page views.
    if (typeof window !== "undefined") {
      if (typeof gtag !== "undefined") {
        gtag("consent", "update", {
          ad_storage: "granted",
        });
        gtag("consent", "update", {
          ad_user_data: "granted",
        });
        gtag("consent", "update", {
          ad_personalization: "granted",
        });
        gtag("consent", "update", {
          analytics_storage: "granted",
        });
        gtag("consent", "update", {
          functionality_storage: "granted",
        });
        gtag("consent", "update", {
          personalization_storage: "granted",
        });
      }
      if (typeof window.fbq !== "undefined") window.fbq("consent", "grant");
      if (typeof (window as any).clarity !== "undefined")
        (window as any).clarity("consent");
    }

    // Adds hotjar script to the body
    if (typeof document !== "undefined") {
      (function (h: any, o: any, t: any, j: any) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = {
          hjid: process.env.GATSBY_HOTJAR_ID,
          hjsv: process.env.GATSBY_HOTJAR_SV,
        };
        let a = o.getElementsByTagName("body")[0];
        let r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }
  };

  return (
    // The CookieConsent component styled with our buttons and uses google analytics as the default cookie.
    // TODO: Implement something on Directus so the wording on the cookie bar isn't hardcoded.
    <CookieConsent
      ref={cookieConsentRef}
      disableStyles={true}
      cookieName="gatsby-gdpr-google-analytics"
      containerClasses={styles.cookieBarContainer}
      buttonWrapperClasses={styles.cookieBarNone}
      buttonClasses={styles.cookieBarNone}
      contentClasses={`inner ${styles.cookieBarContentWrapper}`}
      onAccept={onAcceptAllHandler}
    >
      <div className={styles.cookieBarText}>
        <p>
          This site uses cookies to track performance. More information about
          the cookies used can be found on the{" "}
          <Link
            to="/cookie-policy"
            title="View Cookie Policy"
            className={styles.cookieBarLink}
          >
            cookie policy
          </Link>{" "}
          page.
        </p>
      </div>
      <div className={styles.cookieBarButtons}>
        <ButtonElement
          onClick={() => {
            if (cookieConsentRef.current) cookieConsentRef.current.decline();
          }}
          title="Decline Cookies"
          buttonStyle={ButtonStyle.TransparentBackgroundWhiteBorder}
          className={styles.cookieBarButton}
          childClassNames={{ buttonContent: styles.cookieBarButtonContent }}
        />
        <ButtonElement
          onClick={() => {
            if (cookieConsentRef.current) cookieConsentRef.current.accept();
          }}
          title="Accept Cookies"
          buttonStyle={ButtonStyle.Tertiary}
          className={styles.cookieBarButton}
          childClassNames={{ buttonContent: styles.cookieBarButtonContent }}
        />
      </div>
    </CookieConsent>
  );
};

export default CookieBar;
